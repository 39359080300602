<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <slot />
  </transition>
</template>

<script setup>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const props = defineProps({
  fromY: { type: Number, required: false, default: 60 },
  ease: { type: String, required: false, default: "power1.easeInOut" },
  duration: { type: Number, required: false, default: 2 },
});

gsap.registerPlugin(ScrollTrigger);

const beforeEnter = (el) => {
  gsap.set(el, { opacity: 0, y: `${props.fromY}px` });
};

const enter = (el, done) => {
  gsap.to(el, {
    opacity: 1,
    x: "0",
    y: "0",
    z: "0",
    duration: props.duration,
    ease: props.ease,
    onComplete: done,
    scrollTrigger: {
      trigger: el,
      start: "top 75%",
      end: "bottom 25%",
      scroller: ".sp",
      invalidateOnRefresh: true,
    },
  });
};
</script>
