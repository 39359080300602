<template>
  <div class="content">
    <aside v-if="width > 750" class="left">
      <FadeIn :appear="false">
        <EnterPC v-if="!store.state.entered" />
        <Scene v-else />
      </FadeIn>
    </aside>
    <div class="right">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

import FadeIn from "@/components/atoms/FadeIn";
import EnterPC from "@/components/organisms/EnterPC";
import Scene from "@/components/molecules/Scene";

const store = useStore();
const width = ref(0);

const resize = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  width.value = window.innerWidth;
  window.addEventListener("resize", resize);
});

onUnmounted(() => {
  window.removeEventListener("resize", resize);
});
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100%;
}

.left {
  display: none;

  @include mq("pc") {
    display: block;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    @include left-width();
  }
}

.right {
  width: 100%;
  height: var(--100vh);
  background-image: url(/assets/img/bg.jpg);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  @include mq("pc") {
    margin-left: auto;
    @include right-width();
  }
}

.left-enter {
  width: 100%;
  height: 100%;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
</style>
