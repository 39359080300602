<template>
  <ContainerSP>
    <section class="enter">
      <div class="enter__inner">
        <img
          class="enter__caution"
          src="/assets/img/enter-caution.svg"
          alt="CAUTION"
          width="68"
          height="91"
        />
        <div class="enter__text">
          <p class="enter__en">
            A website to post your thoughts of<br />
            THE FIRST SLAM DUNK.
          </p>
          <p class="enter__en">
            Please enter only if you have watched the movie.
          </p>
          <p class="enter__ja --first">
            『THE FIRST SLAM DUNK』へ<br />
            一人ひとりの感想をつづる特設サイト。
          </p>
          <p class="enter__ja --second">
            映画をご覧になった方のみ、お入りください。
          </p>
        </div>
        <div class="enter__sound">
          <button class="enter__on" @click="() => setSound(false)">
            <img
              src="/assets/img/enter-button-off.png"
              alt="SOUND OFF"
              width="84"
              height="84"
            />
          </button>
          <button class="enter__off" @click="() => setSound(true)">
            <img
              src="/assets/img/enter-button-on.png"
              alt="SOUND ON"
              width="84"
              height="84"
            />
          </button>
        </div>
        <a class="enter__link" href="https://slamdunk-movie.jp/">
          <img
            src="/assets/img/enter-back.svg"
            alt="BACK 公式サイトへ戻る"
            width="80"
            height="43"
          />
        </a>
      </div>
    </section>
  </ContainerSP>
</template>

<script setup>
import { useStore } from "vuex";
import { useAudio } from "@/hooks/useAudio";

import ContainerSP from "@/components/atoms/ContainerSP";

const store = useStore();
const { setAudio, playAudio } = useAudio();

const setSound = (isSound) => {
  if (isSound && !store.state.playing) {
    setAudio();
    playAudio();
  }
  store.state.entered = true;
  store.state.playing = isSound;
};
</script>

<style lang="scss" scoped>
.enter {
  width: 100%;
  height: var(--100vh);
  background-color: #222;
  color: #d4d4d4;
  text-align: center;
}

.enter__inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-height: 640px;
}

.enter__inner > * {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.enter__caution {
  width: 69px;
  top: 10.9489051095%;
}

.enter__text {
  top: 33.3941605839%;
}

.enter__en {
  font-size: 1rem;
  line-height: 1.4;
}

.enter__en:nth-of-type(2) {
  margin-top: 12px;
}

.enter__ja {
  font-size: 1.1rem;
  line-height: 1.4;
  letter-spacing: 0.06em;
}

.enter__ja.--first {
  margin-top: 32px;
}

.enter__ja.--second {
  margin-top: 12px;
}

.enter__sound {
  top: 65.3284671533%;
}

.enter__sound button {
  width: 84px;
}

.enter__on {
  margin-right: 47px;
}

.enter__link {
  display: block;
  width: 80px;
  top: 85.2189781022%;
}
</style>
