export const UA = {
  IS_PC: (() => {
    return window.innerWidth > 750;
  })(),
  IS_IOS: (() => {
    return (
      window.navigator.userAgent.toLowerCase().indexOf("iphone") >= 0 ||
      window.navigator.userAgent.toLowerCase().indexOf("ipad") >= 0 ||
      window.navigator.userAgent.toLowerCase().indexOf("ipod") >= 0
    );
  })(),
  IS_ANDROID: (() => {
    return window.navigator.userAgent.toLowerCase().indexOf("android") >= 0;
  })(),
  IS_IE: (() => {
    return (
      window.navigator.userAgent.toLowerCase().indexOf("msie") >= 0 ||
      window.navigator.userAgent.toLowerCase().indexOf("trident") >= 0
    );
  })(),
};

export const countStrLength = (str) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    str[i].match(/[ -~]/) ? (count += 0.5) : (count += 1);
  }
  return count;
};

export const createUuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (a) {
    let r = (new Date().getTime() + Math.random() * 16) % 16 | 0,
      v = a == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
