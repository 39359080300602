<template>
  <Home :isSubmit="isSubmit" :isError="isError" :onSubmit="handleSubmit" />
</template>

<script setup>
import { onActivated, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import Home from "@/components/templates/Home";
import { useRouter } from "@/hooks/useRouter";

const SUCCESS = 200;
const isError = ref(false);
const isSubmit = ref(false);

const store = useStore();
const { toThoughtsDetail } = useRouter();

const handleSubmit = async (values) => {
  try {
    isSubmit.value = true;

    const { thoughts: message, from: name } = values;
    const data = { message, name };

    const uri = "/api/message/publish";
    const response = await axios.post(uri, data);

    if (!response.data.result || response.status !== SUCCESS) {
      isError.value = !response.data.result;
      console.error(`${response.data.message}`);
      throw "Sorry, something wrong.";
    }

    // エラーの場合ポスティング画面に遷移させたくないため
    store.state.index = 0;
    store.state.preview = values;
    store.state.posting = true;

    // すぐに処理が完了するとポスティング画面を見せられないため
    await setTimeout(() => {
      toThoughtsDetail();
      store.state.posting = false;
    }, 4000);
  } catch (error) {
    isError.value = true;
    console.error(error);
  } finally {
  }
};

onActivated(() => {
  isSubmit.value = false;
});
</script>
