<template>
  <button class="sound-button" :disabled="disabled" @click="handleClick">
    <img
      src="/assets/img/header-sound-on.png"
      :class="{ 'is-active': store.state.playing }"
      alt="SOUND ON"
      width="24"
      height="24"
    />
    <img
      src="/assets/img/header-sound-off.png"
      :class="{ 'is-active': !store.state.playing }"
      alt="SOUND OFF"
      width="24"
      height="24"
    />
  </button>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

import { useAudio } from "@/hooks/useAudio";

const store = useStore();
const { toggle } = useAudio();

const disabled = ref(false);
const handleClick = () => {
  disabled.value = true;
  toggle();
  setTimeout(() => {
    disabled.value = false;
  }, 2000);
};
</script>

<style scoped>
.sound-button {
  width: 24px;
  height: 24px;
  position: relative;
}

.sound-button img {
  display: block;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 2s 1s;
}

.sound-button img.is-active {
  opacity: 1;
}

.sound-button :not(img.is-active) {
  transition: opacity 1s;
  z-index: -1;
}
</style>
