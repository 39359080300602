import { useStore } from "vuex";
import { useRouter } from "@/hooks/useRouter";
import { createUuid } from "@/module/util";
import axios from "axios";

export const useThoughts = () => {
  const SUCCESS = 200;
  const store = useStore();
  const { toThoughtsDetail } = useRouter();

  const getMaxLength = async () => {
    try {
      const uri = `/api/json/messages.json?timestamp=${new Date().getTime()}`;
      const response = await axios.get(uri);
      store.state.cnt = response.data.count;
      store.state.per = response.data.per;
      store.state.max = response.data.count * response.data.per - 1;
    } catch (error) {
      console.error(error);
    }
  };

  const getThoughtsList = async () => {
    if (store.state.cnt === 0 || store.state.loadedCount === store.state.cnt) {
      return;
    }

    try {
      store.state.loading = true;

      const no = String(store.state.loadedCount).padStart(3, "0");
      const uri = `/api/json/messages_${no}.json?timestamp=${new Date().getTime()}`;
      const response = await axios.get(uri);

      if (!response.data.result || response.status !== SUCCESS) {
        console.error(`${response.data.messages}`);
        return;
      }

      const result = response.data.messages.map((it) => ({
        id: createUuid(),
        thoughts: it.message,
        from: it.name,
      }));

      store.state.loaded = result;
      store.state.loadedCount = store.state.loadedCount + 1;
    } catch (error) {
      console.error(error);
    } finally {
      store.state.loading = false;
    }
  };

  const setThoughtsList = async () => {
    if (store.state.loaded.length === 0) {
      await getThoughtsList();
    }

    const list = store.state.loaded.splice(0, store.state.per / 10);
    store.state.thoughts.push(...list);
    store.state.moreCount = store.state.moreCount + 1;

    if (
      store.state.loadedCount * store.state.per >= store.state.max &&
      store.state.loaded.length === 0
    ) {
      store.state.last = true;
    }
  };

  const handleClickItem = (e) => {
    store.state.index = +e.currentTarget.dataset.index;
    toThoughtsDetail();
  };

  const handleClickMore = async () => {
    await setThoughtsList();
  };

  const init = async () => {
    if (store.state.thoughts.length > 0) {
      store.state.last = false;
      return;
    }
    await getMaxLength();
    await setThoughtsList();
  };

  return {
    init,
    getMaxLength,
    setThoughtsList,
    handleClickItem,
    handleClickMore,
  };
};
