<template>
  <List :onClickItem="handleClickItem" :onClickMore="handleClickMore" />
</template>

<script setup>
import { onActivated } from "vue";

import List from "@/components/templates/List";
import { useThoughts } from "@/hooks/useThoughts";

const { init, handleClickItem, handleClickMore } = useThoughts();

onActivated(async () => {
  await init();
});
</script>

<style lang="scss" scoped>
.thoughts__detail {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
