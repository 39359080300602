<template>
  <div class="scroll">
    <div class="scroll__text">
      <img
        src="/assets/img/scroll-text.svg"
        alt="SCROLL"
        width="47"
        height="10"
      />
    </div>
    <div class="scroll__arrow">
      <img src="/assets/img/scroll-arrow.svg" alt="↓" width="4" height="32" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes arrow {
  0% {
    bottom: 100%;
  }

  30% {
    clip-path: inset(0 0 0 0);
    bottom: 0;
  }

  100% {
    clip-path: inset(100% 0 0 0);
    bottom: 0;
  }
}

.scroll {
  width: 47px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 88.3058470765%;
  transform: translateX(-50%);
  text-align: center;
}

.scroll__text {
  width: 47px;
  height: 10px;
}

.scroll__arrow {
  width: 100%;
  height: 32px;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
}

.scroll__arrow img {
  display: block;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: arrow 1.5s ease-in-out infinite;
}
</style>
