import { useStore } from "vuex";

export const useRouter = () => {
  const store = useStore();

  const toTop = () => {
    store.state.page = "top";
  };

  const toThoughtsList = () => {
    store.state.preview = null;
    store.state.page = "list";
  };

  const toThoughtsDetail = () => {
    store.state.page = "detail";
  };

  return {
    toTop,
    toThoughtsList,
    toThoughtsDetail,
  };
};
