<template>
  <ContainerSP :ref="container">
    <main class="summary">
      <p v-if="isNodata" class="nodata">Waiting for letter...</p>
      <div v-else class="summary__container">
        <transition-group name="list" tag="ul" class="summary__list">
          <li
            v-for="(obj, index) in store.state.thoughts"
            :key="obj.id"
            :data-index="index"
            class="summary__item"
          >
            <button
              class="summary__button"
              :data-index="index"
              @click="onClickItem"
            >
              <p class="summary__content">{{ obj.thoughts }}</p>
              <p class="summary__from">
                <span class="summary__prefix">FROM</span>
                <span class="summary__name">{{ obj.from }}</span>
              </p>
            </button>
          </li>
        </transition-group>
        <div class="summary__more">
          <button
            v-if="!store.state.loading"
            class="summary__more"
            :disabled="store.state.last"
            @click="onClickMore"
          >
            <img
              src="/assets/img/list-more.svg"
              alt="MORE"
              width="31"
              height="30"
            />
          </button>
          <Loader v-else />
        </div>
      </div>
    </main>
  </ContainerSP>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import { computed } from "@vue/reactivity";

import Loader from "@/components/atoms/Loader";
import ContainerSP from "@/components/atoms/ContainerSP";

const container = ref(null);
const props = defineProps({
  onClickItem: { type: Function, required: true },
  onClickMore: { type: Function, required: true },
});

const store = useStore();

const isNodata = computed(
  () =>
    !store.state.loading &&
    !store.state.value &&
    store.state.value?.length === 0
);
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  min-height: 100%;
  position: relative;
  @include font("FOT-Klee-Pro-M");
}

.summary__list {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  margin: 0 auto;
  max-width: 283px;
}

.summary__item {
  display: block;
  width: 100%;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 10%);
  margin-top: 12px;
}

.summary__item:first-of-type {
  margin-top: 0;
}

.summary__content {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
  font-size: 1.4rem;
  color: #464646;
  @include font("FOT-Klee-Pro-DB");
}

.summary__from {
  font-size: 1rem;
  margin-top: 16px;
  color: #464646;
}

.summary__prefix {
  margin-right: 8px;
}

.summary__item button {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  background-color: rgba(255, 255, 255, 0.9);
}

.summary__more {
  display: block;
  margin: 23px auto 0 auto;
  position: relative;
}

.summary__more button:disabled {
  opacity: 0.4;
  cursor: default;
}

.nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-enter-active,
.list-leave-active {
  transition: opacity 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
