<template>
  <div class="enter-pc">
    <img
      class="enter-pc__logo"
      src="/assets/img/logo-pc.jpg"
      alt="SLAM DUNK POST"
      width="522"
      height="589"
    />
  </div>
</template>

<style lang="scss" scoped>
.enter-pc {
  width: 100%;
  height: 100%;
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enter-pc__logo {
  width: 260px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
