import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const beforeEnter = (el) => {
  gsap.set(el, { opacity: 0 });
};

export const enter = (el, done) => {
  gsap.to(el, {
    opacity: 1,
    duration: 2,
    onComplete: done,
  });
};

export const beforeLeave = (el) => {
  gsap.set(el, {
    opacity: 1,
  });
};

export const leave = (el, done) => {
  gsap.to(el, { opacity: 0, duration: 1, onComplete: done });
};

export const headerBeforeEnter = (el) => {
  gsap.set(el, { opacity: 0 });
  const container = document.querySelector(".sp");
  const scrollbarWidth = `${container.offsetWidth - container.clientWidth}px`;
  document.documentElement.style.setProperty(
    "--scrollbarWidth",
    scrollbarWidth
  );
};

export const headerEnter = (el, done) => {
  gsap.to(el, {
    opacity: 1,
    duration: 2,
    delay: 2,
    onComplete: done,
  });
};

export const enterLogo = (el, done) => {
  gsap.to(el, {
    opacity: 1,
    duration: 2.4,
    ease: "Power1.easeInOut",
    delay: 1.5,
    onComplete: done,
  });
};

export const enterScroll = (el, done) => {
  gsap.to(el, {
    opacity: 1,
    duration: 2,
    delay: 1,
    onComplete: done,
  });
};
