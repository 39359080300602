<template>
  <div class="loader">
    <div class="loader__dot"></div>
    <div class="loader__dot"></div>
    <div class="loader__dot"></div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
}

.loader__dot {
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: #f8f8f8;
  opacity: 0.8;
  border-radius: 50%;
  animation: loader 1s infinite alternate;
}

.loader__dot:nth-child(1) {
  animation-delay: 0s;
}

.loader__dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loader__dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loader {
  0%,
  50% {
    opacity: 0.8;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}
</style>
