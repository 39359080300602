<template>
  <section class="form">
    <Container class="form__container">
      <img
        class="form__heading"
        src="/assets/img/form-heading.png"
        alt="Dear『THE FIRST SLAM DUNK』"
        width="299"
        height="18"
      />
      <form class="form__content" @submit="onSubmit">
        <div class="form__item --thoughts">
          <Textarea
            :id="'thoughts'"
            :max="MAX_THOUGHTS_LENGTH"
            :placeholder="'Please write your thoughts of the movie / 映画の感想をお書きください'"
          >
            <img
              class="form__label --thoughts"
              src="/assets/img/form-label-thoughts.png"
              alt="THOUGHTS 感想"
              width="128"
              height="14"
            />
          </Textarea>
        </div>
        <div class="form__item --from">
          <TextField
            :id="'from'"
            :max="MAX_FROM_LENGTH"
            :placeholder="'Name / お名前'"
          >
            <img
              class="form__label --from"
              src="/assets/img/form-label-from.png"
              alt="FROM"
              width="46"
              height="14"
            />
          </TextField>
        </div>
        <div class="form__item --terms">
          <Terms>
            <img
              class="form__label --terms"
              src="/assets/img/form-label-terms.png"
              alt="TERMS OF SERVICE 利用規約"
              width="189"
              height="12"
            />
          </Terms>
        </div>
        <div class="form__item --agree">
          <Checkbox :id="'agree'">
            <img
              class="form__label --agree"
              src="/assets/img/form-label-agree.png"
              alt="I agree to the terms of service 利用規約に同意する。"
              width="283"
              height="12"
            />
          </Checkbox>
        </div>
        <div class="form__item from__submit">
          <button class="form__button" :disabled="disabled || isSubmit">
            <img
              v-if="disabled || isSubmit"
              src="/assets/img/form-button-text-disabled.png"
              alt="POST 投函する"
              width="50"
              height="51"
            />
            <img
              v-else
              src="/assets/img/form-button-text-enabled.png"
              alt="POST 投函する"
              width="50"
              height="51"
            />
          </button>
        </div>
        <div class="submit__error" v-if="isError">
          <p class="en">Failed to send. Please press the button again.</p>
          <p class="ja">送信に失敗しました。再度、投函ボタンを押して下さい。</p>
        </div>
      </form>
    </Container>
  </section>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useForm } from "vee-validate";
import { schema } from "@/module/validation";

import Textarea from "@/components/atoms/Textarea";
import TextField from "@/components/atoms/TextField";
import Checkbox from "@/components/atoms/Checkbox";
import Terms from "@/components/atoms/Terms";
import Container from "@/components/atoms/Container";
import { MAX_FROM_LENGTH, MAX_THOUGHTS_LENGTH } from "@/config";

const props = defineProps({
  isError: { type: Boolean, required: true },
  isSubmit: { type: Boolean, required: true },
  onSubmit: { type: Function, required: true },
});

const { values, handleSubmit } = useForm({
  initialValues: { thoughts: "", from: "", agree: false },
  validationSchema: schema,
});

const onSubmit = handleSubmit((values) => {
  props.onSubmit(values);
});

const disabled = ref(false);
watchEffect(() => {
  const { agree, ...form } = values;
  disabled.value =
    Object.values(form).filter((v) => v === "").length > 0 || !agree;
});
</script>

<style lang="scss" scoped>
.form {
  color: #636363;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 75px;
  background-image: url(/assets/img/form-bg.png);
  box-shadow: 0px 8px 80px 0px rgb(0 0 0 / 15%);
  @include font("Ten-Mincho-Regular");
}

.form__container {
  max-width: 440px;
}

.form__heading {
  width: 299px;
}

.form__content {
  margin-top: 78px;
}

.form__item {
  position: relative;
}

.form__item.--from {
  margin-top: 70px;
}

.form__item.--terms {
  margin-top: 68px;
}

.form__item.--agree {
  margin-top: 20px;
}

.form__label.--thoughts {
  width: 128px;
}

.form__label.--from {
  width: 45px;
}

.form__label.--terms {
  width: 189px;
}

.form__label.--agree {
  width: 271px;
}

.from__submit {
  margin-top: 80px;
}

.submit__error {
  width: 81%;
  margin: 20px auto 0 auto;
  color: #fb2e00;
  font-size: 1rem;
  @include font("NotoSansJP-Regular");
}

.submit__error .ja {
  margin-top: 6px;
}

.form__button {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 82%;
  height: 60px;
  padding-top: 1px;
  margin: 0 auto;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #464646;
  background-color: #464646;
  cursor: pointer;

  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.form__button img {
  width: 50px;
  height: 51px;
}
</style>
