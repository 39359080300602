<template>
  <div class="link">
    <button
      class="link__button --top"
      :class="{ 'is-active': store.state.page === 'list' }"
      @click="toTop"
    >
      <img
        src="/assets/img/header-top.svg"
        alt="< TOP"
        width="24"
        height="24"
      />
    </button>
    <button
      class="link__button --back"
      :class="{ 'is-active': store.state.page === 'detail' }"
      @click="toThoughtsList"
    >
      <img
        src="/assets/img/header-back.svg"
        alt="< BACK"
        width="24"
        height="24"
      />
    </button>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "@/hooks/useRouter";

const store = useStore();
const { toTop, toThoughtsList } = useRouter();
</script>

<style scoped>
.link {
  width: 30px;
  height: 20px;
  position: relative;
}

.link__button {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 3s 1s;
}

.link__button.is-active {
  opacity: 1;
}

.link__button:not(.is-active) {
  transition: opacity 1s;
  z-index: -1;
}

.link__button.--top {
  width: 30px;
}

.link__button.--back {
  width: 41px;
}
</style>
