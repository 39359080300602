<template>
  <div class="sp" :class="{ 'is-list': isListPage }">
    <slot />
  </div>
</template>

<script setup>
import { ref, onUpdated } from "vue";
import { useStore } from "vuex";
const store = useStore();

const isListPage = ref(false);
onUpdated(() => {
  isListPage.value = store.state.page === "list";
});
</script>

<style lang="scss" scoped>
.sp {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.is-list {
  padding-top: 130px;
  padding-bottom: 72px;
  mask-image: linear-gradient(to bottom, transparent 10%, black 15%),
    linear-gradient(to left, black 5%, transparent 0%);
}
</style>
