<template>
  <div class="scene">
    <ul class="scene__list">
      <li
        v-for="(scene, index) in SCENES"
        :key="index"
        class="scene__item"
        :class="{ 'is-active': current === index, 'is-old': old === index }"
      >
        <img :src="scene.src" :alt="scene.alt" width="1430" height="1080" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { SCENES } from "@/config";

const old = ref(-1);
const current = ref(-1);

onMounted(() => {
  const max = SCENES.length - 1;
  current.value = 0;
  setInterval(() => {
    old.value = current.value;
    current.value = current.value >= max ? 0 : current.value + 1;
  }, 7400);
});
</script>

<style lang="scss" scoped>
.scene {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scene__list {
  width: 100%;
  height: 100%;
  position: relative;
}

.scene__item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(1.2);
}

.is-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 3s ease-out, transform 3s ease-out;
}

.is-old {
  transition: opacity 1s ease-out, transform 1s linear 3s;
}

.scene__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
