<template>
  <div class="checkbox">
    <input :id="id" type="checkbox" class="checkbox__input" v-model="value" />
    <label :for="id" class="checkbox__label">
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
  id: { type: String, required: true, default: "" },
});

const { value } = useField(props.id);
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  font-size: 1.2rem;
}

.checkbox__input {
  appearance: none;
  width: 21px;
  height: 21px;
  position: relative;
  margin-right: 8px;
  border: 1px solid #83827f;
  background-color: #fff;
  flex-shrink: 0;
  cursor: pointer;

  &::before {
    content: "";
    display: none;
    width: 21px;
    height: 18px;
    position: absolute;
    left: 3px;
    background-size: 100%;
    background-image: url(/assets/img/form-mark-check.svg);
  }

  &:checked {
    &::before {
      display: block;
    }
  }
}

.checkbox__label {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  align-items: center;
}

.form__label span {
  display: block;
}

.checkbox__label span.en {
  margin-right: 7px;
}
</style>
