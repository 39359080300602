<template>
  <Layout>
    <transition
      appear
      @before-enter="headerBeforeEnter"
      @enter="headerEnter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <Header v-if="store.state.entered && !store.state.posting" />
    </transition>
    <FadeIn :appear="false">
      <keep-alive>
        <EnterSP v-if="!store.state.entered" />
        <Posting v-else-if="store.state.posting" />
        <Home v-else-if="store.state.page === 'top'" />
        <List v-else-if="store.state.page === 'list'" />
        <Detail v-else-if="store.state.page === 'detail'" />
      </keep-alive>
    </FadeIn>
  </Layout>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

import FadeIn from "@/components/atoms/FadeIn";
import Header from "@/components/organisms/Header";
import Layout from "@/components/organisms/Layout";
import EnterSP from "@/components/organisms/EnterSP";
import Posting from "@/components/organisms/Posting";
import Home from "@/components/pages/Home";
import List from "@/components/pages/List";
import Detail from "@/components/pages/Detail";

import { useAudio } from "@/hooks/useAudio";
import {
  headerBeforeEnter,
  headerEnter,
  beforeLeave,
  leave,
} from "@/module/fade";
import { UA } from "@/module/util";

const store = useStore();
const { pauseAudio } = useAudio();

const setHeightVh = () => {
  const height = `${window.innerHeight}px`;
  document.documentElement.style.setProperty("--100vh", height);
};

const resize = () => {
  if (UA.IS_IOS) {
    return false;
  }
  setHeightVh();
};

onMounted(() => {
  setHeightVh();
  window.addEventListener("resize", resize);
  window.addEventListener("blur", pauseAudio);
});

onUnmounted(() => {
  window.removeEventListener("resize", resize);
  window.removeEventListener("blur", pauseAudio);
});
</script>
