export const MAX_FROM_LENGTH = 20;
export const MAX_THOUGHTS_LENGTH = 120;

const SHARE_TAG = "SLAMDUNK,SLAMDUNKMOVIE,SLAMDUNKPOST";
const SHARE_URL = "https://slamdunk-movie-post.jp";
const SHARE_TEXT = `映画『THE FIRST SLAM DUNK』の感想を「SLAM DUNK POST」へ投函しました

`;
const ENCODED_SHARE_TAG = encodeURIComponent(SHARE_TAG);
const ENCODED_SHARE_URL = encodeURIComponent(SHARE_URL);
const ENCODED_SHARE_TEXT = encodeURIComponent(SHARE_TEXT);

export const LINE_URL = `http://line.me/R/msg/text/?${ENCODED_SHARE_TEXT}%20${ENCODED_SHARE_URL}`;
export const FACEBOOK_URL = `http://www.facebook.com/sharer.php?u=${ENCODED_SHARE_URL}`;
export const TWITTER_URL = `http://twitter.com/share?text=${ENCODED_SHARE_TEXT}&hashtags=${ENCODED_SHARE_TAG}&url=${ENCODED_SHARE_URL}`;

export const SCENES = [
  {
    src: `/assets/img/scene01.jpg`,
    alt: "上空から見下ろした浜辺近くの街の風景",
  },
  {
    src: `/assets/img/scene02.jpg`,
    alt: "少年と青年によるフィストパンプのアップ",
  },
  {
    src: `/assets/img/scene03.jpg`,
    alt: "屋外でバスケットボールをする少年と青年",
  },
  {
    src: `/assets/img/scene04.jpg`,
    alt: "コート上に転がった紫と黄色のバスケットボール",
  },
  {
    src: `/assets/img/scene05.jpg`,
    alt: "握られた拳のアップ、手首に巻かれたリストバンド",
  },
  {
    src: `/assets/img/scene06.jpg`,
    alt: "誰もいない屋外バスケットコート、夕暮れ",
  },
  {
    src: `/assets/img/scene07.jpg`,
    alt: "海岸の岩肌が夕日に照らされている",
  },
  {
    src: `/assets/img/scene08.jpg`,
    alt: "浜辺で流木に座っている人の後ろ姿、肩まで伸びた髪が揺れている",
  },
  {
    src: `/assets/img/scene09.jpg`,
    alt: "ハイアングルから円陣を組む湘北バスケ部のメンバー",
  },
  {
    src: `/assets/img/scene10.jpg`,
    alt: "高く打ち上げられたバスケットボール",
  },
];
