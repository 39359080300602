<template>
  <button class="mail-button" @click="toThoughtsList">
    <img
      src="/assets/img/header-mail.png"
      alt="感想一覧"
      width="24"
      height="24"
    />
  </button>
</template>

<script setup>
import { useRouter } from "@/hooks/useRouter";
const { toThoughtsList } = useRouter();
</script>

<style scoped>
.mail-button {
  width: 24px;
  height: 24px;
}

.mail-button img {
  display: block;
  width: 24px;
}
</style>
