<template>
  <div class="textarea">
    <label class="textarea__label" :for="id">
      <slot></slot>
    </label>
    <div class="textarea__wrapper">
      <span class="textarea__border --top" />
      <span class="textarea__border --left" />
      <span class="textarea__border --right" />
      <span class="textarea__border --bottom" />
      <textarea
        class="textarea__input"
        :id="id"
        :placeholder="placeholder"
        v-model="value"
        @paste="handlePaste"
        @keydown.enter="cancelEnter"
      />
    </div>
    <p class="textarea__count">{{ count }}/{{ max }}</p>
    <p v-if="errors?.[0]" class="textarea__error">
      <span class="en">{{ errors[0].en }}</span>
      <span class="ja">{{ errors[0].ja }}</span>
    </p>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useField } from "vee-validate";
import { countStrLength } from "@/module/util";

const props = defineProps({
  id: { type: String, required: true, default: "" },
  max: { type: Number, required: false, default: -1 },
  placeholder: { type: String, required: false, default: "" },
});

const count = ref(0);
const { value, errors, setValue } = useField(props.id);

const cancelEnter = (e) => {
  e.preventDefault();
};

const handlePaste = (e) => {
  const clipboard = e.clipboardData.getData("text/plain");
  clipboard.replaceAll(/\n|\r\n|\r/g, "");
  setValue(value.value + clipboard);
  e.preventDefault();
};

watchEffect(() => {
  count.value = Math.floor(countStrLength(value.value));
});
</script>

<style lang="scss" scoped>
.textarea {
  color: #636363;
  @include font("Ten-Mincho-Regular");
}

.textarea__label {
  display: flex;
  font-size: 1.4rem;
  cursor: pointer;
}

.textarea__wrapper {
  margin-top: 12px;
  position: relative;
}

.textarea__border {
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.textarea__border.--top {
  background-image: url(/assets/img/form-border-horizontal.png);
  width: 103%;
  height: 8px;
  top: -1px;
  left: -3px;
}

.textarea__border.--left {
  background-image: url(/assets/img/form-border-vertical.png);
  width: 8px;
  height: 106%;
  top: -4px;
  left: -2px;
}

.textarea__border.--right {
  background-image: url(/assets/img/form-border-vertical.png);
  width: 8px;
  height: 107%;
  top: -8px;
  right: -3px;
}

.textarea__border.--bottom {
  background-image: url(/assets/img/form-border-horizontal.png);
  width: 103%;
  height: 8px;
  bottom: -3px;
  left: -2px;
}

.textarea__input {
  display: block;
  width: 100%;
  height: 197px;
  padding: 20px 15px 20px 16px;
  line-height: 1.8;
  font-size: 1.6rem;
  color: #646464;
  background-color: #fff;
  word-break: break-all;
  resize: none;
  @include font("FOT-Klee-Pro-M");

  &::placeholder {
    color: #888888;
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    line-height: 1.7;
  }

  &:focus {
    outline: none;
  }
}

.textarea__error {
  position: absolute;
  left: 0;
  bottom: -27px;
  color: #fb2e00;
  font-size: 1rem;
  padding-left: 1.2em;
  transform: translateY(100%);
  letter-spacing: 0.04em;
  @include font("NotoSansJP-Regular");
}

.textarea__error span {
  display: block;
}

.textarea__error .ja {
  margin-top: 3px;
  letter-spacing: 0.06em;
}

.textarea__error::before {
  display: block;
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}

.textarea__count {
  font-size: 1.6rem;
  position: absolute;
  top: calc(100% + 5px);
  right: -1px;
  letter-spacing: 0.04em;
  @include font("EB-Garamond-Regular");
}
</style>
