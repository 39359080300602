<template>
  <div class="container">
    <slot />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 440px;
  padding-left: 24px;
  padding-right: 24px;
}
</style>
