import { useStore } from "vuex";

export const useAudio = () => {
  const store = useStore();

  const setAudio = () => {
    const audio = new Audio(`/assets/sound.mp3`);
    store.state.audio = audio;
    store.state.audio.loop = true;
    store.state.audio.volume = 0.5;
  };

  const playAudio = () => {
    if (!store.state.audio) {
      return;
    }
    store.state.audio.play();
    store.state.playing = true;
  };

  const pauseAudio = () => {
    if (!store.state.audio) {
      return;
    }
    store.state.audio.pause();
    store.state.playing = false;
  };

  const toggle = () => {
    if (!store.state.audio) {
      setAudio();
      playAudio();
      return;
    }

    if (store.state.audio.paused) {
      playAudio();
    } else {
      pauseAudio();
    }
  };

  return {
    setAudio,
    playAudio,
    pauseAudio,
    toggle,
  };
};
