<template>
  <Detail :onClickMore="handleClickMore" />
</template>

<script setup>
import { onActivated } from "vue";

import Detail from "@/components/templates/Detail";
import { useThoughts } from "@/hooks/useThoughts";

const { init, handleClickMore } = useThoughts();

onActivated(async () => {
  await init();
});
</script>
