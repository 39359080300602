<template>
  <ContainerSP>
    <main class="detail">
      <div class="detail__container">
        <Swiper
          :slidesPerView="1.2"
          :spaceBetween="-30"
          :centeredSlides="true"
          :mousewheel="true"
          :speed="2800"
          :virtual="true"
          @reachEnd="onReachEnd"
          @swiper="onSwiper"
          @activeIndexChange="onActiveIndexChange"
        >
          <SwiperSlide
            v-for="(obj, index) in [
              store.state.preview,
              ...store.state.thoughts,
            ].filter((it) => it)"
            :key="index"
            :virtualIndex="index"
          >
            <article class="detail__item" :data-index="index">
              <div class="item__inner">
                <h2 class="detail__heading">
                  <span>Dear</span>
                  <span>『THE FIRST SLAM DUNK』</span>
                </h2>
                <p class="detail__content" lang="en">
                  {{ obj.thoughts }}
                </p>
                <p class="detail__from">
                  <span>FROM</span>
                  <span>{{ obj.from }}</span>
                </p>
              </div>
            </article>
          </SwiperSlide>
          <div class="detail__buttons">
            <button
              class="detail__prev"
              @click="handleClickPrev"
              :disabled="isFirstItem || disabledPrev"
            >
              <img
                src="/assets/img/detail-prev.svg"
                alt="PREV"
                width="56"
                height="23"
              />
            </button>
            <button
              v-if="store.state.page === 'detail' && store.state.preview"
              class="detail__share"
              @click="share"
              :ref="shareRef"
            >
              <img
                src="/assets/img/detail-share.svg"
                :class="{ 'is-active': isShare }"
                alt="SHARE"
                width="56"
                height="23"
              />
            </button>
            <button
              class="detail__next"
              @click="handleClickNext"
              :disabled="isLastItem || disabledNext"
            >
              <img
                src="/assets/img/detail-next.svg"
                alt="NEXT"
                width="56"
                height="23"
              />
            </button>
          </div>
          <FadeIn>
            <KeepAlive>
              <div
                v-if="isShare"
                class="share"
                aria-label="Share that you have posted 投函したことをシェアしよう"
              >
                <ul class="share__list">
                  <li>
                    <a
                      :href="LINE_URL"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src="/assets/img/icon-line.svg"
                        alt="Line"
                        width="24"
                        height="19"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      :href="FACEBOOK_URL"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src="/assets/img/icon-facebook.svg"
                        alt="Facebook"
                        width="24"
                        height="23"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      :href="TWITTER_URL"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src="/assets/img/icon-twitter.svg"
                        alt="Twitter"
                        width="24"
                        height="19"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </KeepAlive>
          </FadeIn>
        </Swiper>
      </div>
      <Loading :isLoading="store.state.loading" />
    </main>
  </ContainerSP>
</template>

<script setup>
import { ref, onActivated, onDeactivated } from "vue";
import { useStore } from "vuex";
import SwiperCore, { Mousewheel, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

import FadeIn from "@/components/atoms/FadeIn";
import ContainerSP from "@/components/atoms/ContainerSP";
import Loading from "@/components/molecules/Loading";

import { LINE_URL, FACEBOOK_URL, TWITTER_URL } from "@/config";

import "swiper/swiper.min.css";

const props = defineProps({
  onClickMore: { type: Function, required: true },
});

const store = useStore();

const swiperRef = ref(null);
const shareRef = ref(null);
const isFirstItem = ref(false);
const isLastItem = ref(false);
const isShare = ref(false);

SwiperCore.use([Mousewheel, Virtual]);

const attachButtonClass = () => {
  const activeIndex = swiperRef.value.activeIndex;
  isFirstItem.value = activeIndex === 0;
  isLastItem.value =
    store.state.last && activeIndex === store.state.thoughts.length - 1;
};

const onSwiper = (swiper) => {
  swiperRef.value = swiper;
  attachButtonClass();
};

const onActiveIndexChange = async () => {
  attachButtonClass();
};

const onReachEnd = async () => {
  await props.onClickMore();
};

const disabledPrev = ref(false);
const disabledNext = ref(false);

const prev = () => {
  swiperRef.value.slidePrev();
};

const next = async () => {
  swiperRef.value.slideNext();
};

const handleClickPrev = () => {
  disabledPrev.value = true;
  prev();
  setTimeout(() => {
    disabledPrev.value = false;
  }, 2800);
};

const handleClickNext = () => {
  disabledNext.value = true;
  next();
  setTimeout(() => {
    disabledNext.value = false;
  }, 2800);
};

const share = () => {
  isShare.value = !isShare.value;
};

const hide = (e) => {
  if (isShare.value && !e.target.closest(".detail__share")) {
    isShare.value = false;
  }
};

onActivated(async () => {
  if (store.state.index === store.state.thoughts.length - 1) {
    await props.onClickMore();
  }
  swiperRef.value.slideTo(store.state.index, 0, true);
  window.addEventListener("click", hide);
});

onDeactivated(() => {
  window.removeEventListener("click", hide);
});
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  margin: 0 auto;
  height: var(--100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  @include font("FOT-Klee-Pro-M");
}

.detail__container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: $max-width;
  min-height: 420px;
  position: relative;
}

.swiper {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  overflow: visible;
}

.detail__item {
  width: 100%;
  height: 420px;
  text-align: left;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.04em;
  color: #464646;
  position: relative;
  background-image: url("/assets/img/letter.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 10%);
  transform: scale(0.7);
  transition: opacity 3s 3s ease-in-out, transform 2.8s ease-out;
}

.swiper-slide-active .detail__item {
  opacity: 1;
  transform: scale(1);
}

.swiper-slide-prev .detail__item,
.swiper-slide-next .detail__item {
  box-shadow: none;
  transition: opacity 3s, transform 2s;
}

.swiper-slide-prev .detail__item {
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0) 30%
  );
}

.swiper-slide-next .detail__item {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0) 30%
  );
}

.item__inner {
  width: 100%;
  height: 100%;
  background-image: url("/assets/img/letter.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 13.7931034483% 11.9122257053%;
  opacity: 0;
  transition: opacity 2s;
}

.swiper-slide-active .item__inner {
  opacity: 1;
  transition: opacity 3s 2s ease-in-out;
}

.detail__heading {
  width: 100%;
  line-height: 1;
  letter-spacing: 0;
  @include font("FOT-Klee-Pro-DP");
}

.detail__content {
  width: 100%;
  margin-top: 16%;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.detail__from {
  width: 100%;
  line-height: 2.2;
  text-align: right;
  margin-top: 1%;
  white-space: nowrap;
  font-size: 1.2rem;
}

.detail__from span {
  display: block;
  letter-spacing: 0;
}

.detail__from span:nth-of-type(2) {
  letter-spacing: 0.02em;
}

.detail__buttons {
  position: absolute;
  top: 106%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 46px;
}

.detail__prev,
.detail__next {
  opacity: 1;
  transition: opacity 1s;
}

.detail__prev:disabled,
.detail__next:disabled {
  opacity: 0.6;
}

.detail__share {
  position: absolute;
  top: -22%;
  left: 50%;
  transform: translateX(-50%);
}

.detail__share img {
  transition: filter 1s;
  filter: invert(0);
}

.detail__share img.is-active {
  filter: invert(0.8);
}

.share {
  width: 64%;
  min-height: 122px;
  background-image: url(/assets/img/tooltip.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 4%;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  @include z-index("share");
}

.share__list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22%;
}

.share__list li {
  margin-right: 12.9032258065%;
  width: 9.67741935484%;
}

.share__list li:last-of-type {
  margin-right: 0;
}
</style>
