<template>
  <div class="statement">
    <img
      class="statement__heading --en"
      src="/assets/img/statement-heading-en.png"
      alt="Post your thoughts of “THE FIRST SLAM DUNK”"
      width="218"
      height="44"
    />
    <img
      class="statement__heading --ja"
      src="/assets/img/statement-heading-ja.png"
      alt="『THE FIRST SLAM DUNK』の感想を投函しよう"
      width="323"
      height="66"
    />
    <img
      class="statement__notice"
      src="/assets/img/statement-notice.png"
      alt="※Please enter in Japanese or English ※日本語または英語で入力してください"
      width="187"
      height="32"
    />
  </div>
</template>

<style lang="scss" scoped>
.statement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statement__heading.--en {
  width: 215px;
}

.statement__heading.--ja {
  width: 299px;
  margin-top: 34px;
}

.statement__notice {
  width: 187px;
  margin-top: 69px;
}
</style>
