import { createStore } from "vuex";

export default createStore({
  state: {
    page: "top",
    audio: null,
    playing: false,
    entered: false,
    posting: false,
    cnt: 0,
    per: 0,
    max: 0,
    moreCount: 0,
    loadedCount: 0,
    index: 0,
    last: false,
    loading: false,
    preview: null,
    loaded: [],
    thoughts: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
