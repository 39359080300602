<template>
  <div class="term">
    <p class="term__label">
      <slot></slot>
    </p>
    <div class="term__content">
      <h3>【免責事項】</h3>
      <p>
        投稿されたメッセージは内容を確認後に当サイトに掲載されます。必ずしも掲載されるとは限りませんのであらかじめご了承ください。<br />
        投稿いただいた内容は、東映株式会社（以下「弊社」）の裁量により適宜編集の上、宣伝に活用させていただく場合がございます。<br />
        また、当WEBサイトへ表示するほか、作品公式サイト、作品公式Twitter、Instagramおよびその他の媒体で掲載・紹介する場合がございます。あらかじめご了承ください。
      </p>
      <h3>【個人情報の保護について】</h3>
      <p>
        投稿者の個人データは個人情報保護法にもとづき厳重に管理し、本投稿の目的以外で使用することはありません。<br />
        個人情報は、弊社にて厳重に管理し、正当な理由なく第三者への開示、譲渡及び貸与することは一切ありません。<br />
        ただし、弊社との間で機密保持契約を締結した第三者に対し、この投稿に関する業務を委託する場合を除きます。<br />
        詳しくは弊社個人情報保護ポリシーをご覧ください。<br />
        <a
          href="https://www.toei.co.jp/policy/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.toei.co.jp/policy/index.html
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.term {
  @include font("NotoSansJP-Regular");
}

.term__content {
  width: 100%;
  padding: 20px 16px;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #83827f;
  overflow: hidden;
  position: relative;
  color: #464646;
  font-size: 1rem;
  margin-top: 3.4%;
}

.term__content h3 {
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0;
  }
}

.term__content p {
  margin-top: 4px;
  line-height: 1.6;
}

.term__content a {
  border-bottom: 1px solid #222;
  padding-bottom: 0.01em;
  display: inline-block;
}

.term__label {
  display: flex;
  font-size: 1.2rem;
  letter-spacing: 0.04em;
  @include font("Ten-Mincho-Regular");
}

.term__label span {
  display: block;
}

.term__label .en {
  margin-right: 1em;
}
</style>
