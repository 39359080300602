import * as yup from "yup";
import { countStrLength } from "@/module/util";
import { MAX_FROM_LENGTH, MAX_THOUGHTS_LENGTH } from "@/config";

const REQUIRED_MESSAGE_EN = "Required fields.";
const REQUIRED_MESSAGE_JA = "必須項目です。";
const EXCEEDED_MESSAGE_EN = "Character count exceeded.";
const EXCEEDED_MESSAGE_JA = "文字数が超過しています。";
const PROHIBIT_MESSAGE_EN = "Contains invalid characters.";
const PROHIBIT_MESSAGE_JA = "使用できない文字が含まれています。";

const REQUIRED_MESSAGE = { en: REQUIRED_MESSAGE_EN, ja: REQUIRED_MESSAGE_JA };
const EXCEEDED_MESSAGE = { en: EXCEEDED_MESSAGE_EN, ja: EXCEEDED_MESSAGE_JA };
const PROHIBIT_MESSAGE = { en: PROHIBIT_MESSAGE_EN, ja: PROHIBIT_MESSAGE_JA };

const EXCLUDE_JA_EN_NUM_AND_SYMBOL =
  /^[a-zａ-ｚ0-9０-９ぁ-んァ-ヶー\u4E00-\u9FFF!-/:-@¥[-`{-~！-／：-＠［-｀｛-～、-〜"'・”’‘￥\s]*$/i;

const checkIsExceededCharLimit = (str, max) => countStrLength(str) <= max;

export const schema = yup.object({
  thoughts: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test("isMatchThoughts", PROHIBIT_MESSAGE, (str) =>
      str.match(EXCLUDE_JA_EN_NUM_AND_SYMBOL)
    )
    .test("isExceedThoughts", EXCEEDED_MESSAGE, (str) =>
      checkIsExceededCharLimit(str, MAX_THOUGHTS_LENGTH)
    ),
  from: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test("isMatchFrom", PROHIBIT_MESSAGE, (str) =>
      str.match(EXCLUDE_JA_EN_NUM_AND_SYMBOL)
    )
    .test("isExceedFrom", EXCEEDED_MESSAGE, (str) =>
      checkIsExceededCharLimit(str, MAX_FROM_LENGTH)
    ),
  agree: yup.boolean().nullable(),
});
