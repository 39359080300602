<template>
  <div class="textfield">
    <label :for="id" class="textfield__label">
      <slot></slot>
    </label>
    <div class="textfield__wrapper">
      <input
        type="text"
        class="textfield__input"
        :id="id"
        :placeholder="placeholder"
        v-model="value"
      />
    </div>
    <p class="textfield__count">{{ count }}/{{ max }}</p>
    <p v-if="errors?.[0]" class="textfield__error">
      <span class="en">{{ errors[0].en }}</span>
      <span class="ja">{{ errors[0].ja }}</span>
    </p>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useField } from "vee-validate";
import { countStrLength } from "@/module/util";

const props = defineProps({
  id: { type: String, required: true, default: "" },
  max: { type: Number, required: false, default: -1 },
  placeholder: { type: String, required: false, default: "" },
});

const count = ref(0);
const { value, errors } = useField(props.id);

watchEffect(() => {
  count.value = Math.floor(countStrLength(value.value));
});
</script>

<style lang="scss" scoped>
.textfield {
  color: #636363;
  @include font("Ten-Mincho-Regular");
}

.textfield__label {
  cursor: pointer;
}

.textfield__wrapper {
  margin-top: 5px;
  position: relative;
}

.textfield__wrapper::after {
  width: 102%;
  height: 108%;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: -4px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  background-image: url(/assets/img/form-border-horizontal.png);
  pointer-events: none;
}

.textfield__input {
  width: 100%;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  display: block;
  width: 100%;
  line-height: 1.8;
  font-size: 1.6rem;
  color: #646464;
  background-color: #fff;
  @include font("FOT-Klee-Pro-M");

  &::placeholder {
    color: #888888;
    font-size: 1.4rem;
    letter-spacing: 0.04em;
  }

  &:focus {
    outline: none;
  }
}

.textfield__count {
  font-size: 1.6rem;
  position: absolute;
  top: calc(100% + 5px);
  right: -1px;
  letter-spacing: 0.04em;
  @include font("EB-Garamond-Regular");
}

.textfield__error {
  position: absolute;
  left: 0;
  bottom: -23px;
  color: #fb2e00;
  font-size: 1rem;
  padding-left: 1.2em;
  transform: translateY(100%);
  letter-spacing: 0.04em;
  @include font("NotoSansJP-Regular");
}
.textfield__error span {
  display: block;
}

.textfield__error .ja {
  margin-top: 3px;
  letter-spacing: 0.06em;
}

.textfield__error::before {
  display: block;
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
</style>
